const sessionDescriptionTemplatesDictionaryData: Record<string, string> = {
    1: 'Rozmowa wstępna to doskonała okazja do zapoznania się z mentorem i określenia celów nauki. Podczas tej sesji omówimy Twoje potrzeby i oczekiwania oraz zaplanujemy kolejne kroki, aby maksymalnie wykorzystać nasze wspólne sesje.',
    2: 'Jeden na jeden to indywidualna sesja, podczas której mentor skupi się na Twoich specyficznych potrzebach i wyzwaniach. Idealne dla głębokiego zanurzenia się w wybrane tematy i uzyskania szczegółowych wskazówek oraz praktycznych porad.',
    3: 'Przygotowanie CV obejmuje przegląd i optymalizację Twojego CV, aby przyciągnąć uwagę rekruterów. Mentor pomoże Ci wyróżnić kluczowe osiągnięcia i umiejętności, a także dostosować dokument do specyficznych wymagań rynku pracy.',
    4: 'Plan nauki to strategiczne sesje, w których tworzymy spersonalizowany plan nauki dostosowany do Twoich celów i harmonogramu.',
    5: 'Przegląd projektu to sesja, w której mentor analizuje i ocenia Twój projekt. Otrzymasz konstruktywną informację zwrotną oraz wskazówki.',
    6: 'Przygotowanie do rozmowy to intensywna sesja, w której mentor przeprowadzi symulację rozmowy kwalifikacyjnej.',
    7: 'Rozmowa z ekspertem to sesja, w której masz możliwość uzyskania porad od specjalisty w danej dziedzinie.',
    9: 'Chat 30m/60m to elastyczna sesja, która może trwać 30 lub 60 minut.',
    10: 'Pair programming to praktyczna sesja programowania z mentorem, gdzie współpracujecie nad kodem w czasie rzeczywistym.',
    11: 'Szkolenie dla kadry kierowniczej ma na celu rozwijanie kompetencji zarządzających zespołami oraz projektami.',
    12: 'IT Project Guidance to wsparcie mentora przy planowaniu i realizacji projektów IT.',
    13: 'Design thinking i kreatywność to sesja poświęcona rozwiązywaniu problemów poprzez metodyki design thinking.',
    14: 'Strategia biznesowa i innowacje to sesja, w której analizujesz rynek i tworzenie strategii.',
    15: 'Coaching rozwoju kariery to wsparcie w planowaniu ścieżki zawodowej.',
    16: 'Trendy marketingowe to sesja, w której mentor prezentuje najnowsze techniki marketingowe.',
    17: 'Podstawy UI/UX Design obejmują zasady projektowania interfejsów oraz doświadczeń użytkownika.',
    18: 'Techniki projektowania graficznego to zaawansowana sesja o kompozycji, typografii i kolorystyce.',
    19: 'Optymalizacja UX to sesja skupiona na doskonaleniu doświadczeń użytkowników.',
    20: 'Strategie wizualnego brandingu pomagają budować silną tożsamość marki.',
    21: 'Warsztaty kreatywnego projektowania rozwijają umiejętności twórcze.',
    22: 'Skuteczny networking to budowanie wartościowych relacji zawodowych.',
    23: 'Konsulting biznesowy to sesja wsparcia w rozwiązywaniu problemów związanych z zarządzaniem firmą.',
    24: 'Sesja Twój startup to konsultacje dotyczące rozwoju i prowadzenia firmy od podstaw.',
    25: 'Analiza i wizualizacja danych to sesja poświęcona interpretacji i prezentacji wyników analizy danych.',
    26: 'Projektowanie interfejsów użytkownika to sesja o tworzeniu intuicyjnych aplikacji.',
    27: 'Przegląd architektury oprogramowania to sesja oceny i optymalizacji struktury aplikacji.',
    28: 'Strategie chmurowe to sesja na temat wdrażania rozwiązań chmurowych.',
    29: 'Metodologie Agile to sesja poświęcona zastosowaniu Agile w zarządzaniu projektami.',
    30: 'Najlepsze praktyki DevOps to sesja optymalizacji operacji w środowisku programistycznym.',
    31: 'Podstawy Cybersecurity wprowadzają w kluczowe zagadnienia bezpieczeństwa IT.',
    32: 'Zarządzanie infrastrukturą IT to sesja optymalizacji wydajności i bezpieczeństwa.',
    33: 'Transformacja cyfrowa to sesja wdrażania technologii cyfrowych w firmie.',
    34: 'Analiza finansowa to sesja o ocenie kondycji finansowej firmy.',
    35: 'Strategia sprzedaży to sesja budowania skutecznych technik sprzedażowych.',
    36: 'Zarządzanie relacjami z klientami to sesja o budowaniu długoterminowych relacji.',
    37: 'Strategia eCommerce to sesja optymalizacji sprzedaży online.',
    38: 'Badania rynku to sesja o analizie rynku i potrzeb klientów.',
    39: 'Relacje publiczne to sesja budowania pozytywnego wizerunku publicznego.',
    40: 'Tworzenie treści to sesja poświęcona rozwijaniu strategii content marketingu.',
    41: 'Zarządzanie mediami społecznościowymi to sesja o angażowaniu użytkowników online.',
    42: 'Zarządzanie marką to sesja o budowaniu i utrzymaniu wizerunku marki.',
    43: 'Mapowanie ścieżki użytkownika analizuje interakcje użytkowników z produktem.',
    44: 'Projektowanie usług skupia się na tworzeniu wysokiej jakości usług.',
    45: 'Kreatywne pisanie rozwija umiejętności tworzenia angażujących treści.',
    46: 'Pozyskiwanie funduszy dla startupu to sesja o fundraisingu.',
    47: 'Rozwój MVP to sesja o tworzeniu minimalnych wersji produktów.',
    48: 'Tworzenie modelu biznesowego analizuje i planuje strategię firmy.',
    49: 'Ocena dopasowania produktu do rynku to sesja na temat PMF.',
    50: 'Skalowanie startupu to sesja o rozwoju i ekspansji firmy.',
    51: 'Warsztat Lean Startup rozwija zwinne podejście do rozwoju firmy.',
    52: 'Coaching pitchu dla inwestorów przygotowuje do prezentacji dla inwestorów.',
    53: 'Budowanie zespołu dla startupów to sesja o rekrutacji i zarządzaniu zespołem.',
    54: 'Sesja Growth Hackingu rozwija strategie szybkiego wzrostu firmy.',
    55: 'Planowanie strategii wejścia na rynek to sesja strategiczna.',
    56: 'Aspekty prawne startupów to sesja na temat prawnych podstaw firmy.',
    57: 'Matchmaking współzałożycieli to sesja o wyborze odpowiednich partnerów biznesowych.',
    58: 'Design sprint to szybka sesja projektowania produktu.',
    59: 'Warsztaty badań użytkowników analizują potrzeby klientów.',
    60: 'Testowanie użyteczności to warsztaty na temat optymalizacji doświadczeń.',
    61: 'Wireframing i prototypowanie to sesja o tworzeniu prototypów produktów.',
    62: 'Tworzenie systemów projektowych organizuje zasady i narzędzia UX/UI.',
    63: 'Testowanie dostępności zapewnia, że produkty są zgodne z wymogami dostępności.',
    64: 'Kreatywne rozwiązywanie problemów to sesja na temat innowacyjnych podejść do problemów.',
    65: 'Teoria kolorów i typografia to sesja na temat estetyki projektowania.',
    66: 'Projektowanie Mobile-First to sesja o tworzeniu responsywnych aplikacji mobilnych.',
    67: 'Audyt UX to ocena i optymalizacja doświadczeń użytkownika.',
    68: 'Strategie marketingu cyfrowego rozwijają techniki marketingu online.',
    69: 'Optymalizacja SEO poprawia widoczność w wynikach wyszukiwania.',
    70: 'Reklama w mediach społecznościowych to sesja o promocji w social media.',
    71: 'Strategia treści analizuje skuteczność tworzonych materiałów.',
    72: 'Zarządzanie kampaniami influencerów rozwija współpracę z liderami opinii.',
    73: 'Warsztaty strategii marki budują tożsamość firmy.',
    74: 'Optymalizacja współczynnika konwersji analizuje efektywność kampanii.',
    75: 'Automatyzacja email marketingu to sesja o automatycznych kampaniach.',
    76: 'Strategia marketingu afiliacyjnego rozwija współpracę z partnerami.',
    77: 'Taktyki video marketingu to sesja na temat skutecznych kampanii wideo.',
    78: 'Przegląd analizy marketingowej ocenia dane z działań marketingowych.',
    79: 'Strategia pozyskiwania klientów rozwija metody na zwiększenie liczby klientów.',
    80: 'Rozwój kariery to sesja o planowaniu zawodowym.',
    81: 'Profesjonalny networking to sesja o budowaniu relacji w branży.',
    82: 'Umiejętności rozmowy kwalifikacyjnej to sesja przygotowująca do rekrutacji.',
    83: 'Umiejętności negocjacyjne pomagają w uzyskiwaniu lepszych ofert.',
    84: 'Budowanie marki osobistej rozwija spójny wizerunek profesjonalny.',
    85: 'Tworzenie CV to sesja optymalizująca dokumenty aplikacyjne.',
    86: 'Tworzenie listu motywacyjnego to sesja dopasowania listu do ofert.',
    87: 'Strategie poszukiwania pracy pomagają skuteczniej znaleźć zatrudnienie.',
    88: 'Zmiany kariery to sesja wspierająca przejścia między zawodami.',
    89: 'Negocjacje płacowe pomagają uzyskać lepsze warunki finansowe.',
    90: 'Zarządzanie stresem to sesja o technikach radzenia sobie ze stresem.',
    91: 'Zarządzanie czasem to sesja o efektywnym planowaniu dnia pracy.',
    92: 'Budowanie odporności rozwija umiejętność radzenia sobie z trudnościami.',
    93: 'Myślenie krytyczne to sesja o analizie problemów i logicznych rozwiązaniach.',
    94: 'Rozwiązywanie problemów to sesja pomagająca znajdować praktyczne rozwiązania.',
    95: 'Inteligencja emocjonalna pomaga lepiej zarządzać emocjami w pracy.',
    96: 'Równowaga praca-życie to sesja o zdrowym balansie między pracą a odpoczynkiem.',
    97: 'Podejmowanie decyzji to sesja wspierająca w efektywnym podejmowaniu wyborów.',
    98: 'Zwiększanie kreatywności rozwija techniki stymulujące twórcze myślenie.',
    99: 'Samomotywacja to sesja o rozwijaniu wewnętrznej motywacji.',
    100: 'Default session description.'
};

export default sessionDescriptionTemplatesDictionaryData;
